import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React, { useEffect, useReducer } from 'react';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import { App } from './App';
import { config } from './config';
import reportWebVitals from './reportWebVitals';
import { createBrowserHistory } from 'history';

Sentry.init({
  dsn: config.sentry.dsn,
  autoSessionTracking: true,
  environment: config.environment || 'production',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const history: any = createBrowserHistory();

const Root = ({ children }: { children: any }) => {
  const [, forceUpdate] = useReducer(x => x, 0);

  useEffect(() => {
    history.listen(() => {
      forceUpdate();
    });
  }, []);

  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <QueryParamProvider history={history}>
      <Root>
        <App />
      </Root>
    </QueryParamProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
