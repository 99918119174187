import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { MessageBox } from './pages/MessageBox';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

export const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/message-box" component={MessageBox} />
      </Switch>
    </Router>
  );
};
