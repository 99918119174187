import axios from 'axios';

export const createApiClient = () => {
  const client = axios.create({
    baseURL: '/api',
    withCredentials: true,
  });

  client.interceptors.request.use(config => {
    const token = sessionStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
  });

  client.interceptors.response.use(
    response => response.data,
    error => Promise.reject(error)
  );

  const shortenUrl = (url: string): Promise<{ shortUrl: string }> => client.post('/link/shorten', { url });

  return { shortenUrl };
};
