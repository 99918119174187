import { createDetailsWidget } from '@livechat/agent-app-sdk';
import { IDetailsWidget } from '@livechat/agent-app-sdk/types/widgets/details';
import { Button, InputField, Loader } from '@livechat/design-system';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { useClient } from '../hooks/useClient';
import { useLivechat } from '../hooks/useLivechat';

const Container = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 3% 4%;
  font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
`;

const ButtonContainer = styled.div`
  height: auto;
  margin-left: 2%;
`;

const StyledInputField = styled(InputField)`
  width: 100%;

  & input {
    width: 100%;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 2;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResultContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > * {
    margin-left: 0.5rem;
  }
`;

const Link = styled.a`
  color: #4379d6;
  margin-left: 0;

  &:visited,
  &:active,
  &:hover {
    color: #4379d6;
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

export const MessageBox = () => {
  const [url, setUrl] = useState('');
  const client = useClient();

  const { loading, error } = useLivechat({ loginUrl: '/api/agent/livechat' });

  const [widget, setWidget] = useState<IDetailsWidget | null>(null);

  const { data: result, error: shortenError, run: shorten } = useAsync<{ shortUrl: string }>({
    deferFn: ([url]) => client.shortenUrl(url),
  });

  useEffect(() => {
    createDetailsWidget()
      .then(widget => setWidget(widget))
      .catch(error => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Container>
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      </Container>
    );
  }
  return (
    <Container>
      <FormContainer>
        <StyledInputField
          value={url}
          name="url"
          required
          error={shortenError ? 'Please check the url' : error ?? undefined}
          id="url"
          placeholder="Url..."
          description={!shortenError ? 'Enter url to shorten' : undefined}
          onChange={e => {
            e.preventDefault();
            setUrl(e.target.value);
          }}
        />
        <ButtonContainer>
          <Button
            kind="primary"
            onClick={e => {
              e.preventDefault();
              shorten(url);
            }}
          >
            Shorten
          </Button>
        </ButtonContainer>
      </FormContainer>
      <ResultContainer>
        <Link href={result?.shortUrl} target="_blank">
          {result?.shortUrl}
        </Link>
        <Spacer />
        <CopyToClipboard text={result?.shortUrl ?? ''}>
          <Button disabled={!result?.shortUrl} size="compact" kind="secondary">
            Copy
          </Button>
        </CopyToClipboard>
        <Button
          disabled={!result?.shortUrl}
          onClick={() => {
            widget?.putMessage(result?.shortUrl || '');
          }}
          size="compact"
          kind="secondary"
        >
          Append
        </Button>
      </ResultContainer>
    </Container>
  );
};
